import menu from '../../assets/img/menu.png';
import menuWhite from '../../assets/img/menuWhite.png';
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import '../../assets/css/menu.css';

export const Menu = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();


    const handleNavToggle = () => {
    
       
        setIsNavOpen(!isNavOpen);

        // if(!isNavOpen) {
        //     document.body.classList.add('ac-filter-blur');
        // } else{
        //     document.body.classList.remove('ac-filter-blur'); 
        // }
      };

    return(
        <div className="ac-menu" >
            <button id="ac-open-nav" onClick={handleNavToggle}>
                <img src={menuWhite} className="ac-menu-btn-img ac-img-responsive" alt="Click here to open menu" />
            </button>
            <div className="ac-pos-rel-nav">
            <div className={isNavOpen ? 'ac-opened ac-blur' : 'ac-blur'}></div>
            {isNavOpen && (
                <nav className={isNavOpen ? 'ac-opened' : ''}>
                    <button id="ac-close-nav" onClick={handleNavToggle}>
                        <span>+</span>
                    </button>
                    <ul>
                        <li>
                        <Link to="/"  onClick={() => {TagManager.dataLayer({ dataLayer: { event: "home_page" } });}}>עמוד הבית</Link>
                        </li>
                        <li>
                        {/* <Link to="/takanon"  onClick={() => {TagManager.dataLayer({ dataLayer: { event: "takanon" } });}}>תקנון</Link> */}
                            <a href="takanon.pdf" target="_blank" rel="noopener" className='ac-takanon'>תקנון</a>
                        </li>
                        <li>
                        <Link to="/faq"  onClick={() => {TagManager.dataLayer({ dataLayer: { event: "questions_answers" } });}}>שאלות ותשובות</Link>
                        </li>
                        <li>
                        <Link to="/prices"  onClick={() => {TagManager.dataLayer({ dataLayer: { event: "prizes" } });}}>פרסים</Link>
                        </li>
                        <li>
                        <Link to="/truma"  onClick={() => {TagManager.dataLayer({ dataLayer: { event: "truma" } });}}>תרומה</Link>
                        </li>
                    </ul>
                </nav>
      )}
           
            </div>
        </div>
      
    )
};

export default Menu;