import React, { useState, useEffect, createRef } from 'react';
import { Menu } from '../Components/Menu/Menu.js';


// Import Css
import pricespage from '../assets/css/pricespage.css';


// Iport images
import  footer_img  from '../assets/img/1.jpg';
import  milka_logo_img  from '../assets/img/milka_logo.png';


export const PricesPage = () => {
    return (
        <div className="ac-page ac-page-prices">
            <Menu />
            <div className="ac-container">
                <img src={milka_logo_img} className="ac-img-responsive ac-logo-img-milka" alt="milka logo" />
                <h1>פרסים</h1>
                <h2>
                    המקום הראשון   
                </h2>
                <p>
                יזכה לשני כרטיסי טיסה לאיטליה <br/>
וכניסה לפסטיבל השוקולד!
                </p>
                <h2>
                המקום השני
                </h2>
                <p>
                     פינוק זוגי הכולל שני לילות <br/>
                    במלון יוקרה בישראל
   
                </p>
                <h2>
                המקום השלישי
                </h2>
                <p>יזכה בזוג כרטיסים לסרט <span className='ac-en-light'>VIP</span></p>
                {/* <img src={footer_img} className="ac-img-responsive ac-footer-img-milka" alt="milka footer" /> */}
            </div>
        </div>
    );
}

export default PricesPage;