import React, { useState, useEffect } from 'react';
import imageData from '../../assets/json/imageData';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import '../../assets/css/vrscene.css'

const VRScene = ({updateValue}) => {
//   const encodedImages = [imageData.image1, imageData.image2, imageData.image3, imageData.image4];
  const [dataArray, setDataArray] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageName, setImageName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [randomNumber, setRandomNumber] = useState(null);


   // Function to check if the device is a mobile device
   const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };
 
  useEffect(() => {



   
    // Function to generate a random number from 0 to 4
    const getRandomNumber = () => {
        const min = 0;
        const max = 4; // Max value is exclusive, so we use 4 to include numbers up to 3 (0 to 3).

        // Calculate a random number within the range [0, 4)
        const newRandomNumber = Math.floor(Math.random() * (max - min)) + min;

       // Set the random number state and mark loading as complete
        setRandomNumber(newRandomNumber);
       
        console.log(newRandomNumber);
        setIsLoading(false); 
    };
    getRandomNumber();
    

    // GEt Json data
    const fetchData = async () => {
      try {
      
        const response = await fetch('/images.json'); // URL for JSON data
        const data = await response.json();

        // Extracting values from the JSON object and creating an array
        const dataArray = Object.values(data);

        setDataArray(dataArray);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
   }, []);

   setTimeout(() => {
    const newValueFromB = randomNumber;
    updateValue(newValueFromB); // Call the callback function to update the value in ComponentA
  }, 1000); 

 
// console.log(randomNumber);
const convertToDataURL = (encodedImage) => {
    return `data:image/jpeg;base64,${encodedImage}`;
};


const imageUrl = convertToDataURL(dataArray[randomNumber]);
 
// console.log(imageUrl);




  return (
    <div className="ac-vrscene"  transfervalue={randomNumber}>

        {isLoading ? (
                <div>
                    <h2>Loading...</h2>
                     {/* add a spinner or other loading indicators here */}
                </div>
            ) : (
              <Scene>
                <a-scene>
                    <a-sky src={imageUrl}></a-sky>
                </a-scene>

                <Entity id="popup" visible={!isMobileDevice()}>
                  {/* Popup content here */}
                  <Entity
                    id="popupContent"
                    geometry={{ primitive: 'plane', width: 2, height: 1 }}
                    material={{ color: 'white' }}
                    position={{ x: 0, y: 1.6, z: -3 }}
                  />
                </Entity>
              </Scene>
         
          
            )}
    </div>
  );
};

export default VRScene;