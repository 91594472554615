// AudioPlayer.js
import React, { useRef, useEffect, useState } from 'react';

// Import Css
import '../../assets/css/audio.css';

//  GEt an audio file
import  audio_src  from '../../assets/audio/33816586_jodel-time_by_bequadro_preview.mp3';

const Audio = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
  
    useEffect(() => {
      // Automatically play the audio on component mount
      audioRef.current.play();
      setIsPlaying(true);
    }, []);
  
    // Function to handle audio play/pause
    const toggleAudio = () => {
      const audioElement = audioRef.current;
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    };
  
    return (
      <div>
        <audio ref={audioRef} controls autoPlay>
          {/* Replace "song.mp3" with the path to your audio file */}
          <source src={audio_src} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <button onClick={toggleAudio}>
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      </div>
    );
  };
  
  export default Audio;
