import React, { useState, useEffect, createRef } from 'react';
import { Menu } from '../Components/Menu/Menu.js';
import VRScene from '../Components/VRScene/VRScene.js';
import Audio from '../Components/Audio/Audio.js';
// import  Input  from '../Components/Input/Input.js';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useNavigate  } from 'react-router-dom'; // Assuming you are using React Router
import TagManager from "react-gtm-module";
import InputMask from 'react-input-mask';
// import MaterialInput from '@material-ui/core/Input';

// Import Css
import gamepage from '../assets/css/gamepage.css';


// Iport images
import  footer_img  from '../assets/img/1.jpg';
import  parashut_img  from '../assets/img/2.png';
import  cloud_img  from '../assets/img/3.png';
import  arrowImg  from '../assets/img/ctrl-left.svg';
import  inputImg  from '../assets/img/input_lne.png';



export const GamePage = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [timeOver, setTimeOver] = useState(false);
    const [numberChoosenImage, setNumberChoosenImage] = useState('Initial Value'); // This is number of the image user looking for milka ( there are 4 images )
    const [canFinish, setCanFinish] = useState(false);
    const [userAnswer, setUserAnswer] = useState('');
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [userTime, setUserTime] = useState(null);
    const [userStoped, setUserStoped] = useState(false);
    const [stopTime, setStopTime] = useState(false);
 
    const removeUnderscoresFromNumber = (numberWithUnderscores)=> {
        const numberAsString = numberWithUnderscores.toString();
        const numberWithoutUnderscores = numberAsString.replace(/_/g, '');
        return Number(numberWithoutUnderscores);
      }

    const handleChange = (event) => {


        // var result = event.target.value;
        const result = removeUnderscoresFromNumber(event.target.value);
       
        setUserAnswer(result);
        
      
        localStorage.setItem('userAnswer', result);
        setUserStoped(true);
    };
    const ref = createRef();

   // Function to update the numberChoosenImage
   const updateValueInNumImage = (newValue) => {

        if(newValue == null) {
            setCanFinish(false);
            
            return false;
        } else {
            setNumberChoosenImage(newValue);
            setCanFinish(true);
            localStorage.setItem('num_image', numberChoosenImage);
           
        
        }
        // alert(newValue);
      
        console.log('the image is: ', numberChoosenImage);
    };

    // Function to update the time 
    const renderTime = ({ remainingTime }) => {

       
        if (remainingTime === 0) {
          return <div className="timer timer-over">נגמר הזמן</div>;
        }
      
        return (
          <div className="timer">
            {/* <div className="text">Remaining</div> */}
            <div className="value">{remainingTime}</div>
            {/* <div className="text">seconds</div> */}
          </div>
        );
    };

    // Function to remove a class from all elements
    function removeClassFromAllElements(className) {
        const elements = document.getElementsByTagName('*');

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.classList.contains(className)) {
                element.classList.remove(className);
            }
        }
    }
    

    // Go toFormPage
    const goToFormPage = () => {
        if(userAnswer !== '' && userAnswer !== 0){
            removeClassFromAllElements('a-fullscreen');

           if(!timeOver){
                const value = document.querySelector('.value');
                // Get the text content from the element
                const textInValue = value.textContent;
                console.log(textInValue); 
                const textInValueJSON = JSON.stringify(textInValue);
                localStorage.setItem('user_cont', textInValueJSON);
         
           }
          
            navigate('/form');
        }else if( userAnswer == '0') {
            alert('אנא הקלד ניחוש יותר גדול מ-0')
        } else {
            alert('שכחת להקליד את הניחוש שלך')
        }
        
    }

    useEffect(() => { 
        
    }, []);



    return (
        <div className="ac-page ac-page-game">
            
            { !timeOver ? (
                            <div className="ac-container ac-vrscene-gamepage">
                               
                                            <Audio />
                                            <VRScene updateValue={updateValueInNumImage}/>  
                                            <div className="ac-timer-container">s
                                                    <CountdownCircleTimer
                                                        isPlaying
                                                        duration={30}
                                                        strokeWidth={10}
                                                        colors={['#393a90', '#b8b6da']}
                                                        rotation={'counterclockwise'}
                                                        colorsTime={[30, 0]}
                                                        onComplete={() => {
                                                            // do your stuff here
                                                            // alert('stop');
                                                            setTimeOver(true);
                                                            localStorage.setItem('user_cont', 0);
                                                            return { shouldRepeat: false, delay: 1.5 } // repeat animation in 1.5 seconds
                                                            }}
                                                        >
                                                        {renderTime}
                                                    </CountdownCircleTimer>
                                            </div>
                                            <div className="ac-content">
                                                <span className="ac-title-inner-gamepage">הקלידו את הניחוש שלכם</span>
                                                <div className="ac-input-wrpper">
                                                    <div className="ac-gradient ac-gradient-top"></div>
                                                    <div className="ac-input">
                                                 
                                                {/* <InputMask
                                                    mask="999"
                                                    type="tel"
                                                    placeholder="_ _ _"
                                                    value={userAnswer}
                                                    onChange={handleChange}
                                                   />  */}
                                                    <input
                                                      maxLength={'3'}
                                                        type="tel"
                                                        placeholder="_ _ _"
                                                        value={userAnswer}
                                                      onChange={handleChange}
                                                   /> 
                                                    </div>
                                                    <div className="ac-gradient ac-gradient-bottom"></div>
                                                </div>
                                                <button onClick={() => {
                                                    TagManager.dataLayer({ dataLayer: { event: "right" } })
                                                    goToFormPage();
                                                    }} className="ac-btn">
                                                    <span>צדקתי?</span>
                                                    <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="לחץ כאן למעבר  לעמוד  שלחיתצ פרטים" />
                                                </button>
                                            </div>
                                      
                                
                             </div>
                         ) : (
                           
                            <div className="ac-container ac-inner-gamepage">
                                 <Menu />
                                <img src={parashut_img} className="ac-img-responsive ac-parashut-img-milka" alt="festimilka" />
                               
                                <div className="ac-content">
                                    <span className="ac-title-inner-gamepage">הקלידו את הניחוש שלכם</span>
                                    <div className="ac-input-wrpper">
                                        <div className="ac-gradient ac-gradient-top"></div>
                                        <div className="ac-input">
                                                {/* <InputMask
                                                        mask="999"
                                                        type="tel"
                                                        placeholder="_ _ _"
                                                        value={userAnswer}
                                                      onChange={handleChange}
                                                   />  */}
                                                   <input
                                                      maxLength={'3'}
                                                        type="tel"
                                                        placeholder="_ _ _"
                                                        value={userAnswer}
                                                      onChange={handleChange}
                                                   /> 
                                        </div>
                                        <div className="ac-gradient ac-gradient-bottom"></div>
                                    </div>
                                    <button onClick={() => goToFormPage()} className="ac-btn">
                                        <span>צדקתי?</span>
                                        <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="לחץ כאן למעבר  לעמוד  שלחיתצ פרטים" />
                                    </button>
                                </div>
                                <img src={cloud_img} className="ac-img-responsive ac-cloud-img-milka" alt="festimilka" />
                                <img src={footer_img} className="ac-img-responsive ac-footer-img-milka" alt="festimilka" />
                              
                            </div>
                         )
            }
        </div>

    )
}

export default GamePage;