import React, { useState, useEffect } from 'react';


export const Countdown = ({updateCountDown}) => {
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    } else {
      // Countdown is completed, show the alert
      // alert('Countdown reached 0!');

      updateCountDown('ac-available')
    }
  }, [seconds]);

  return (
    <div>
      <span className='ac-countdown'>{seconds}</span>
    </div>
  );
};

export default Countdown;