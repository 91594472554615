import React, { useState, useEffect, createRef } from 'react';
import { Menu } from '../Components/Menu/Menu.js';


// Import Css
import takanonpage from '../assets/css/takanonpage.css';


// Iport images
import  footer_img  from '../assets/img/1.jpg';
import  milka_logo_img  from '../assets/img/milka_logo.png';


export const TakanonPage = () => {
    return (
        <div className="ac-page ac-page-takanon">
            <Menu />
            <div className="ac-container">
                <img src={milka_logo_img} className="ac-img-responsive ac-logo-img-milka" alt="milka logo" />
                <h1>תקנון</h1>
                <div className="ac-text-takanon">
                    <span>
                    תקנון השתתפות בפעילות "פסטימילקה" הוראות כלליות <br/><br/>
1. מונדלז יורופ סרביסז ג'י אם בי איץ (להלן: "החברה") תקיים בתקופת הפעילות, כהגדרתה להלן, פעילות משתמשים באינטרנט, אשר תופיע ב"מיני סייט" באתר האינטרנט בכתובת: ,festimilka.co.il שיהיה זמין בגלישה באמצעות טלפון נייד חכם (להלן: "הפעילות" ו-"אתר הפעילות" בהתאמה). <br/><br/>
2. במסגרת הפעילות, תסתייע החברה בשירותי משרד הפרסום בי אן ווי חברה לפרסום בע״מ (להלן: "משרד הפרסום").<br/><br/> 
3. התקנון מיועד לשני המינים כאחד. <br/><br/>
4. כותרות הסעיפים נועדו לנוחיות הקורא/ת בלבד ולא ישמשו לפרשנותו של התקנון. הזכאות להשתתפות בפעילות ותקופת הפעילות <br/><br/>
5. כל אדם פרטי, תושב ישראל ו/או אזרח ישראל ו/או אדם בעל היתר שהייה חוקית בישראל אשר גילו עולה על 16 שנים במועד השתתפותו בפעילות, רשאי להשתתף בפעילות. על אף האמור מובהר, כי השתתפות משתתף/ת אשר גילו/ה אינו עולה על 18 שנים במועד הפעילות (להלן: "קטין") תהא כפופה לאישור אחד מהוריו/ה (או אפוטרופוס חוקי אחר), בהתאם להוראות תקנון זה. <br/><br/>
6. הפעילות תתקיים בין התאריכים: 01.08.2023 בשעה 10:00 ועד 14.08.2023 בשעה 23:59 (להלן: "תקופת הפעילות"). כל יום במהלך תקופת הפעילות בימים א' עד ה' מהשעה 00:00 ועד השעה 23:59 ייחשב כ-"יום פעילות". ימי שישי מהשעה 00:00 ועד שבת בשעה 23:59 ייחשבו יחד כיום פעילות אחד. <br/><br/>
7. שעון החברה הוא השעון הקובע לעניין תקופת הפעילות ולעניין יום הפעילות. <br/><br/>
8. החברה רשאית לשנות את תקופת הפעילות, לקצרה או להאריכה, על פי שיקול דעתה הבלעדי וללא הודעה מראש. במהלך תקופת הפעילות החברה תהיה רשאית, בהתאם לשיקול דעתה הבלעדי, להשהות את הפעילות לפרקי זמן קצרים ולצרכים טכניים. כיצד משתתפים בפעילות <br/><br/>
9. במהלך תקופת הפעילות, המבקשים/ות להשתתף בפעילות יוכלו לשחק במשחק וירטואלי (להלן: "המשחק") באתר הפעילות. במסגרת הפעילות יתבקש/תתבקש המשתתף/ת לאתר, באחד מהחדרים הוירטואליים 360 מעלות, שיוצג בפניו/ה, את מספר חפיסות שוקולד מילקה המופיעות בחדר (להלן: "חפיסות מילקה"), בפרק זמן שלא יעלה על 30 שניות ולהקליד את התשובה במקום המיועד לכך. מטרת המשחק היא לנקוב מהו את מספר חפיסות שוקולד המילקה המסתתרות בחדר בזמן המענה הקצר ביותר. <br/><br/>
10. יצוין, כי הפעילות תתבצע באמצעות מספר חדרים וירטואליים אשר בכל אחד מהם יהיה מספר שונה של חפיסות שוקולד. החברה אינה מתחייבת איזה חדר יוצג למשתתף במהלך הפעילות. <br/><br/>
11. על מנת להיחשב כמשתתף/ת בפעילות (להלן: "המשתתף"), על המבקש/ת להשתתף בפעילות לבצע את כל הפעולות הבאות במהלך תקופת הפעילות: <br/><br/>
10.1 לרכוש במהלך תקופת הפעילות (בין התאריכים ה- 01.08.2023 ליום 14.08.2023) אחד או יותר ממוצרי שוקולד ו/או עוגיות מילקה של היבואנית, דיפלומט מפיצים בע"מ 1968, בסך של 20 שקלים ומעלה (להלן: "הרכישה"), ולשמור את חשבונית הרכישה (להלן: "חשבונית הרכישה"). <br/><br/>
10.2 לקרוא ולאשר את תקנון הפעילות אשר יימצא באתר הפעילות; <br/><br/>
10.3 להשתתף במשחק באתר הפעילות; <br/><br/>
10.4 למלא באתר הפעילות את פרטי ההתקשרות של המבקש/ת להשתתף בפעילות - שם, שם משפחה, טלפון נייד, כתובת דואר אלקטרוני (להלן: "פרטי ההתקשרות").<br/><br/> 
10.5 להעלות צילום של חשבונית הרכישה לאתר הפעילות בהתאם לאחת מהחלופות הבאות: <br/><br/>
1. מיד בתום המשחק; או <br/><br/>
2. במועד מאוחר יותר במהלך תקופת הפעילות באמצעות קישור שישלח במסרון למבקש/ת להשתתף בפעילות למספר הטלפון שהזין/ה באתר הפעילות. <br/><br/>
12. ההשתתפות במשחק אינה מוגבלת בניסיונות, בכפוף למגבלות שלהלן: <br/><br/>
11.1 כל משתתף/ת רשאי/ת להעלות חשבונית רכישה אחת בגין כל ניסיון השתתפות במשחק; <br/><br/>
11.2 משתתף/ת יוכל/תוכל לזכות פעם אחת בלבד בכל תקופת הפעילות, ובהתאם לניקוד הגבוה ביותר שקיבל/ה אותו/ה משתתף/ת בכל תקופת הפעילות. <br/><br/>
13. מובהר כי ״משתתף/ת״ לצורך תקנון זה ייחשב/תחשב מי שמילא/ה את פרטי ההתקשרות, קרא/ה ואישר/ה את התקנון, העלה/תה את חשבונית הרכישה לאתר הפעילות, וביצע/ה את כל הפעולות הנדרשות בתקנון זה, ולא כל אדם אחר שהוא.
14. למען הסר ספק מובהר בזאת, כי רק משתתפים/ות שפרטיהם/ן נקלטו בפועל אצל החברה ו/או באתר הפעילות, לרבות צירוף חשבונית רכישה תקינה ותקפה ביחס לרכישה שבוצעה במהלך תקופת הפעילות, באופן נכון ומדויק יהיו/תהיינה רשאים/ות להשתתף בפעילות. בחירת הזוכים/ות והפרסים <br/><br/>
15. בסיום תקופת הפעילות, תבחר החברה, מבין כל המשתתפים/ות שעמדו בתנאי התקנון במשך כל תקופת הפעילות, את המשתתפים/ות שיסיימו את המשחק בתוצאות הגבוהות ביותר (נקבו במספר חפיסות השוקולד הקרוב ביותר של חפיסות מילקה בחדר הוירטואלי ואשר סיימו את המשחק בזמן הקצר ביותר), ויעמדו ביתר הוראות תקנון זה לרבות העלאת חשבונית רכישה (להלן: הזוכים/ות"). זוכים/ות אלה יהיו זכאים לפרסים שיפורטו להלן (להלן: "הפרסים"). <br/><br/>
16. הפרס במקום הראשון: המשתתף/ת אשר יזכה/תזכה בתוצאה הגבוהה ביותר (נקבו במספר חפיסות השוקולד הקרוב ביותר של חפיסות מילקה בחדר הוירטואלי ושסיים/ה את המשחק בזמן הקצר ביותר מבין כל המשתתפים/ות) יזכה/תזכה בחבילת נופש זוגית לפסטיבל השוקולד באיטליה 2023 Eurochocolate festival of Perugia ,The למימוש בין התאריכים 13.10.2023 עד ה- 23.03.2023 , בשווי של כ- 20,000 שקלים (להלן: "חבילת הנופש לפסטיבל"). פרטי חבילת הנופש לפסטיבל יימסרו לזוכים עם זכייתם. <br/><br/>
17. הפרס במקום השני: שני/שתי המשתתפים/ת הבאים/ות שיזכו בתוצאה הגבוהה ביותר (נקבו במספר חפיסות השוקולד הקרוב ביותר של חפיסות מילקה בחדר הוירטואלי ושסיימו את המשחק בזמן הקצר ביותר לאחר הזוכה במקום הראשון), יזכו בחופשה זוגית בת 2 לילות במלון ספא בישראל (על בסיס לינה וארוחת בוקר) + טיפול ספא זוגי במלון (להלן: "חופשה בישראל"). פרטי המלון והאירוח יימסרו לזוכים עם זכייתם. 18. הפרס במקום השלישי: עשרים המשתתפים/ות הבאים שיזכו בתוצאה הגבוהה ביותר (נקבו במספר חפיסות השוקולד הקרוב ביותר של חפיסות מילקה בחדר הוירטואלי ושסיימו את המשחק בזמן הקצר ביותר לאחר הזוכים/ות במקום השני), יזכו בפרס המקום השלישי: זוג כרטיסים VIP לסרט בסינמה סיטי (להלן: "זוג כרטיסים לסרט"). <br/><br/>
19. לאחר שהחברה תודיע לזוכה במקום הראשון על זכייתו/ה בחבילת הנופש לפסטיבל ולזוכים/ות במקום השני על זכייתם בחופשה בישראל, תקשר החברה בין הזוכה במקום הראשון לבין ספק חבילת הנופש לפסטיבל, חברת איסתא ישראל בע"מ ובין הזוכה במקום השני לבין ספק החופשה בישראל (להלן: "הספק"). <br/><br/>
20. יובהר כי רכיבי חבילת הנופש לפסטיבל וכן רכיבי החופשה בישראל, לרבות, אך לא רק, חברת התעופה; מועדי הטיסות; בתי המלון, המיקום והדירוג שלהם; סוג האירוח במלונות; סוגי הפעילויות; העברות משדה התעופה וחזרה; לוחות זמנים וכיוצ"ב, יקבעו בהתאם לשיקול דעתה הבלעדי של הספק הרלוונטי ו/או מי מטעמו והפרטים המלאים אודות רכיבי החבילות יימסר לזוכים/ות על ידי הספק הרלוונטי עם זכייתם/ן בפעילות. <br/><br/>
21. האחריות על חבילת הנופש לפסטיבל וכן על החופשה בישראל, ובכלל זה האחריות להזמנת הטיסות וזמני הטיסות, המלונות וטיבם, שירותי העברה והכניסה לפסטיבל, מוטלת באופן בלעדי על הספק הרלוונטי ו/או מי מטעמו ולזוכים/ות לא תהיה כל טענה או זכות בעניין זה כלפי החברה ו/או כלפי מי מטעמה. בכל שאלה, בקשה, תקלה או טענה בכל הנוגע לחבילת הנופש לפסטיבל ו/או לחופשה בישראל יש לפנות לספק הרלוונטי בלבד שהוא האחראי הבלעדי כלפי הזוכים/ות בנוגע לחבילות הנופש. 22. כל שינוי, כלל שהדבר יהיה אפשרי, מכל סיבה שהיא בחופשה שהוזמנה יעשה ישירות מול הספק הרלוונטי ובכפוף לתנאים ולמדיניות של הספק הרלוונטי, חברות התעופה והמלונות הרלוונטיים. שינוי בהזמנה כאמור יהיה באחריותו/ה של הזוכה ועל חשבונו/ה. <br/><br/>
23. החברה תשלח לעשרים הזוכים/ות במקום השלישי שובר דיגיטלי (להלן: "השובר") המקנה זוג כרטיסים לסרט באולמות ה- VIP בסינמה סיטי וזאת באמצעות הדואר האלקטרוני שמסר/ה הזוכה במסגרת פרטי ההתקשרות. לצורך מימוש זוג הכרטיסים לסרט, על הזוכה לפעול בהתאם להנחיות שיועברו אליו/ה ביחד עם השובר. מימוש זוג הכרטיסים לסרט הינו בכפוף לתנאים המפורטים בהודעה הנלווית לשובר, בשובר ובאתר האינטרנט של סינמה סיטי ולא תהיה לזוכה כל טענה ו/או דרישה ו/או תביעה כנגד החברה או מי מטעמה בגין כך. מובהר בזאת כי תוקף מימוש זוג הכרטיסים לסרט הינו כפי שיפורט בהודעה הנלווית לשובר. <br/><br/>
24. יובהר כי מימוש זוג הכרטיסים לסרט יעשה על ידי הזוכים/ות ישירות מול סינמה סיטי, ובכפוף לאישור סינמה סיטי אשר יינתן על בסיס התנאים, המדיניות והאילוצים של סינמה סיטי, לרבות מגוון הסרטים שיוקרנו, זמני הקרנת הסרטים, המקומות הפנויים וכיוצ"ב, ולזוכים/ות לא תהיה כל טענה או זכות בעניין זה כלפי החברה ו/או מי מטעמה. בכל שאלה, תקלה או טענה הנוגעת לזוג הכרטיסים לסרט ו/או מימושו יש לפנות לסינמה סיטי בלבד, שהיא האחראית הבלעדית כלפי הזוכים/ות בפרס בעניין זה. החברה ו/או מי מטעמה לא יישאו באחריות בקשר עם זוג הכרטיסים לסרט, ולכל נזק העלול להיגרם לזוכים/ות ו/או למי מטעמם/ן ו/או לכל צד שלישי. הוראות כלליות בקשר לזוכים/ות ולפרסים <br/><br/>
25. אם שני/שתי משתתפים/ות או יותר יסיימו את המשחק בתוצאה זהה, תינתן עדיפות בדירוג למשתתף/ת שהשתתף/ה במשחק במועד מוקדם יותר בתקופת הפעילות והמשתתף/ת שהשתתף/ה במועד המאוחר יותר יוצב/תוצב במקום הבא בדירוג. <br/><br/>
26. אם מי מהזוכים/ות הינו/הינה קטין/ה, קבלת הפרסים תהא כפופה לקבלת אישור בכתב להשתתפות הקטין/ה בפעילות (ובכלל זה אישור הוראות תקנון הפעילות) ולקבלת הפרסים, חתום על-ידי אחד מהוריו/ה (או על-ידי אפוטרופוס חוקי אחר) וכן צילום תעודת הזהות של ההורה המאשר (או של האפוטרופוס החוקי האחר), הכולל את ספח תעודת הזהות במסגרתו מצוין שמו/ה של הקטין/ה המשתתף/ת, וזאת לא יאוחר מ-5 ימי עבודה ממועד קבלת ההודעה בדבר הזכייה (להלן: "אישור אפוטרופוס"). <br/><br/>
27. בתוך 7 ימי עסקים מתום תקופת הפעילות, תשלח החברה לזוכים/ות הודעה על הזכייה בדואר אלקטרוני ו/או באמצעות הטלפון הנייד, בהתאם לפרטי ההתקשרות שמולאו על ידם באתר הפעילות והזוכים/ות יידרשו להציג לחברה תוך 72 שעות צילום של תעודת הזהות וצילום של חשבונית הרכישה. במידה שהזוכים/ות לא יענו ו/או לא יציגו תוך 72 שעות ממועד הפנייה אליהם צילום תעודת זהות ו/או צילום חשבונית הרכישה, יועבר הפרס המיועד לו/לה למשתתף/ת הבא/ה בדירוג. <br/><br/>
28. כתנאי לקבלת הפרס על כל זוכה להציג תעודה מזהה, את חשבונית הרכישה אשר זיכתה אותו/ה להשתתף בפעילות ולזכות בה וכן לחתום על אישור קבלת הפרס. יובהר, כי עורכת המבצע תהא רשאית להתנות את מימוש הפרסים בתנאים נוספים, לפי שיקול דעתה הבלעדי, כפי שאלו יימסרו על ידה ו/או על ידי מי מטעה. <br/><br/>
29. מבלי לגרוע מהאמור לעיל, במקרה שהזוכה הציג/ה חשבונית רכישה שהפרטים בה אינם מופיעים בשלמות, או שהמודפס עליה טושטש או שאינו ברור די צרכו, לחברה שיקול דעת בלעדי לפסול את הזכייה, בהתאם לכל מקרה לגופו ולמשתתפים/ות בפעילות ו/או לכל מי מטעמם לא תהא כל טענה בגין כך. <br/><br/>
30. החברה ו/או מי מטעמה יהיו רשאים לקבוע כי מקום מסירת הפרס יהיה ברשות הרבים, כגון ליד משרדי החברה, או בכל מקום אחר שהחברה תקבע, ובמעמד פומבי, בנוכחות אנשי ציבור ואמצעי התקשורת ואנשים אחרים, הכול כפי שיקבע על ידי החברה. <br/><br/>
31. הזכות לקבלת הפרסים ו/או את חבילת הנופש לפסטיבל ו/או החופשה בישראל הינה אישית ואינה ניתנת להמחאה ו/או להעברה לאחר (למעט לאפוטרופוס במקרה של זוכה שהוא/שהיא קטין/ה). <br/><br/>
32. זוכה אשר לא נוצר עמו/ה קשר בחלוף 72 שעות מהניסיון הראשון של החברה ליצור עמו/ה קשר, ו/או שלא המציא/ה לחברה, כנדרש, אישור אפוטרופוס, ו/או צילום תעודת זהות ו/או צילום חשבונית, או זוכה אשר לא עמד/ה בהוראות תקנון זה, או אם החליטה החברה כי ההשתתפות/זכייה נעשו באמצעי שאינו הוגן, בלתי כשר, מרמה, תחבולה או פעולה שלא כדין או שמצאה החברה כי נפל פגם בהשתתפות/זכייה או במקרה של טעות של החברה, תהא החברה רשאית לקבוע כי השתתפות/זכייה של הזוכה מתבטלת (להלן: "הזוכה המקורי/ת"), ולהחליט אם ברצונה לקבוע תחתיו/ה זוכה אחר/ת, לפי העניין להלן: "הזוכה החדש/ה"), והכל לפי שיקול דעתה הבלעדי של החברה. במקרה כאמור תודיע החברה לזוכה המקורי/ת על ביטול הזכייה שלו/ה וככל שיבחר זוכה חדש/ה, תיידע את הזוכה החדש/ה על זכייתו/ה. הוראות תקנון זה יחולו על הזוכה החדש/ה בהתאמה. אם החברה תבטל השתתפות/ה של זוכה מהטעמים המפורטים לעיל, לזוכה לא תהיה כל טענה ו/או תביעה ו/או דרישה כלפי החברה ו/או מי מטעמה בקשר עם האמור. <br/><br/>
33. מובהר כי זוכה, אשר לא יממש/תממש את הפרס, מכל סיבה שהיא, לא יהא/תהא זכאי/ת לקבל מהחברה זיכוי ו/או פיצוי ו/או שווה ערך אחר. מובהר ומודגש בזאת, כי, הפרסים לרבות חבילת הנופש לפסטיבל והחופשה בישראל אינם ניתנים להחלפה או להמרה למוצר/ים אחר/ים, לכסף, לשווה-כסף או לכל טובת הנאה אחרת כלשהי (למעט כאמור בסעיף 30.11 להלן) . <br/><br/>
34. לחברה שמורה הזכות, בכל עת, לפי שיקול דעתה הבלעדי, להחליף ו/או לשנות כל פרס לרבות את חבילת הנופש לפסטיבל ו/או את החופשה בישראל ו/או את זהות הספק הרלוונטי, ולמשתתף/ת ו/או לזוכה לא תהא כל טענה בעניין זה כנגד החברה ו/או כנגד מי מטעמה. ידוע למשתתפים/ות, כי השתתפותם/ן בפעילות זו הינה למטרת השעשוע ולשם ההנאה המושגת מעצם ההשתתפות בפעילות. במידה ויחול שינוי בפרס עליו הוכרז, לא תהיה למשתתפים/ות כל טענה ו/או תביעה ו/או דרישה כלפי החברה ו/או מי מטעמה בקשר עם האמור. 35. החברה רשאית לפרסם בדף האינסטגרם של החברה תחת שם המשתמש milka_israel (להלן: "דף האינסטגרם של החברה"), מעת לעת, את שמותיהםן של הזוכים/ות כפי שנקלטו באתר הפעילות. החברה רשאית, אך לא חייבת, להציג ולפרסם את שמות הזוכים/ות ו/או המשתתפים/ות ו/או מידע אחר בקשר עם הזוכים/ות ו/או המשתתפים/ות, כולם או חלקם, במהלך תקופת הפעילות ולאחריה, באתר הפעילות ובמקומות אחרים, לפי שיקול דעת החברה. יובהר כי למעט כמצוין בתקנון זה, החברה אינה מחויבת לפרסם או להודיע בכל צורה שהיא מי הם/הן הזוכים/ות. <br/><br/>
36. המשתתף/ת מסכים/ה ומאשר/ת, כי דבר השתתפותו/ה או זכייתו/ה, אפשר שיסוקרו, יצולמו וישודרו, באמצעי תקשורת, לרבות בטלוויזיה, בשילוט, בעיתונות ובדיגיטל, וכי בעצם ההשתתפות בפעילות מביע/ה המשתתף/ת את הסכמתו/ה לפרסום ו/או צילום שמו/ה ותמונותיו/ה במסגרת הסיקורים והפרסומים האמורים, מבלי שתשולם לזוכה תמורה כלשהי. הזוכה מוותר/ת על כל טענה או דרישה או תביעה כלפי החברה בגין כך. <br/><br/>
37. מבלי לגרוע מהאמור לעיל, ההוראות הבאות יחולו ביחס לחבילת הנופש לפסטיבל וביחס לחופשה בישראל: <br/><br/>
30.1 מימוש חבילת הנופש לפסטיבל ו/או החופשה בישראל הינה בכפוף לאמור לעיל ולתנאים הנוספים אשר יפורטו על ידי הספק הרלוונטי. <br/><br/>
30.2 מימוש חבילת הנופש לפסטיבל ו/או החופשה בישראל וכל הפעולות שידרשו לצורך כך יעשו על ידי הזוכה ישירות מול הספק הרלוונטי. <br/><br/>
30.3 חבילת הנופש לפסטיבל הינה למימוש בין התאריכים 13.10.2023 עד ה- 23.03.2023. <br/><br/>
30.4 כל שינוי מכל סיבה שהיא בקשר לחבילת הנופש לפסטיבל והחופשה בישראל, יעשו ישירות מול הספק הרלוונטי ובכפוף לתנאים ולמדיניות של הספק הרלוונטי ו/או מי מטעמו, והכל בהתאם לשיקול דעתו הבלעדי של הספק הרלוונטי, ולזוכה לא תהיה כל טענה או זכות בעניין זה כלפי החברה ו/או מי מטעמה. <br/><br/>
30.5 הזוכה בחבילת הנופש לפסטיבל ו/או בחופשה בישראל יהיה/תהיה אחראי/ת לשלם על חשבונו/ה כל תשלום חובה, בין אם מדובר במס הכנסה ו/או בכל מס אחר, אגרה, היטל, וכיוצ״ב, שעשוי להידרש בקשר עם השתתפותו/ה בפעילות והזכייה בפרס. הזוכה בחבילת הנופש לפסטיבל ו/או בחופשה בישראל יישא/תישא על חשבונו/ה בתשלום הוצאות אישיות ו/או כל הוצאה אחרת אשר אינה חלה במפורש על החברה ו/או מי מטעמה על פי הוראות התקנון, לרבות, כל הוצאה אישית שכרוכה בקבלת ושימוש בפרס, ככל שתידרש, כגון: הוצאת רישיון רכב, אגרות (לרבות אגרת רישוי), ביטוחים, מיסים לרבות מיסי נמל, עלויות נסיעה אל מקום איסוף הרכב, וכיוצ"ב, אשר יחולו באופן מלא ובלעדי על הזוכה לפי העניין. מס עירוני מקומי ישולם ע"י הזוכה במלון ולא ניתן לשלמו מראש. <br/><br/>
30.6 את הזכייה ניתן לממש לכרטיס ו/או לשהות על שם הזוכה והנלווה אליו/ה לפי בחירתו/ה. כרטיסי הטיסה והשהות במלונות של הזוכה והלווה אינם ניתנים להפרדה, ועל הנלווה שיבחר על ידי הזוכה להתלוות אליו/ה באותה הטיסה הן בנסיעה לרומא והן בנסיעה חזרה לישראל. כך גם, השהות בחופשה בישראל של הזוכה והלווה אינם ניתנים להפרדה. <br/><br/>
30.7 האחריות על מימוש חיבלת הנופש לפסטיבל ולחופשה בישראל, ובכלל זה האחריות להזמנת הטיסות, המלונות, שירותי ההעברה, מוטלת באופן בלעדי על הספק הרלוונטי ו/או מי מטעמו, ולזוכים/ות לא תהיה כל טענה או זכות בעניין זה כלפי החברה. בכל שאלה, תקלה או טענה הנוגעת לחופשה ו/או מימושה יש לפנות לספק הרלוונטי בלבד, שהוא האחראי הבלעדי כלפי הזוכה בנוגע לחופשות. החברה ו/או מי מטעמה לא יישאו באחריות בקשר עם החופשה, לרבות טיבה ואיכותה, ולכל נזק העלול להיגרם לזוכה ו/או למי מטעמו/ה ו/או לכל צד שלישי. <br/><br/>
30.8 הזוכים/ות יידרשו להמציא לספק הרלוונטי את כל המסמכים שיידרשו על ידי החברה ו/או הספק הרלוונטי עבורם/ן ועבור הנלווה, הנדרשים לצורך מימוש הזכייה והזמנת חבילת הנופש לפסטיבל ו/או החופשה בישראל, כולל ויזות מתאימות (ככל שהדבר נדרש) צילום ת.ז., דרכון בתוקף , כשרות, שחרור מאחריות והיתר לצורכי יחסי ציבור. <br/><br/>
30.9 הוצאת והצגת התעודות והמסמכים הנדרשים כאמור הן ביחס לזוכה והן ביחס לנלווה הנה באחריות הזוכים/ות ועל חשבונם/ן. <br/><br/>
30.10 חבילת הנופש לפסטיבל והחופשה בישראל אינם כוללים כיסוי ביטוחי מכל סוג שהוא. ביטוח, מכל סוג שהוא, של החופשה ו/או של הזוכה והנלווה, במהלך החופשה, ככל שייעשה, יהיו על חשבונם של הזוכה והנלווה ובאחריותם הבלעדית. 
30.11 הזוכה יהיה/תהיה רשאי/ת, באישור מראש של הספק הרלוונטי, להזמין חופשה בעלות זהה משווי ההטבה הנקובה לעיל, ובלבד שניתן אישור של הספק הרלוונטי לכך מראש. מובהר בזאת כי בשום מקרה החברה לא תידרש לשלם יותר או לשאת בעלות העולה על שווי ההטבה. מגבלות סייגים ופטור מאחריות <br/><br/>
38. בכל מקרה לא תהיה למשתתף/ת ו/או לזוכים/ות כל תביעה ו/או דרישה ו/או טענה כלפי החברה וכל גוף אחר הקשור בפעילות, או מי מטעם מי מאלה, בכל עניין ודבר הקשור, במישרין או בעקיפין, בפעילות, לרבות ומבלי לגרוע מכלליות האמור לעיל, בכל הקשור לאופן בחירת הזוכה ו/או קבלת הפרסים, בקשר עם טיבם, איכותם, תכונותיהם, תקלות או מימוש הפרסים ו/או השימוש בהם, לביטול זכייה ו/או החלפת זוכה ולשינוי תנאי הפעילות והתקנון. המשתתף/ת מצהיר/ה בזאת, כי התחייבותו/ה זו הנה בלתי חוזרת ולא ניתנת לביטול או לשינוי. <br/><br/>
39. ההשתתפות בפעילות הינה באחריותו הבלעדית של המשתתף/ת, והחברה ו/או מי מטעמה לא יישאו באחריות כלשהי לכל נזק אשר יגרם למשתתף/ת ו/או לצד ג' כלשהו עקב ו/או בקשר עם השתתפותם בפעילות ו/או מימוש הפרסים, והמשתתף/ת פוטר/ת בזאת את החברה ו/או מי מטעמה באופן מוחלט, סופי ובלתי חוזר מאחריות לכל נזק, הפסד, אובדן או הוצאה, מכל מין וסוג שהוא, אשר נגרמו ו/או ייגרמו כתוצאה מההשתתפות בפעילות, זכייה ו/או קבלת הפרסים. <br/><br/>
40. החברה ו/או מי מטעמה לא תוכל להתחייב ולא מתחייבת שהפעילות לא תופרע או תתקיים כסדרה ללא מגבלות או הפסקות, תנוהל ללא טעויות, תהא חסינה מפני נזקים, קלקולים, אי דיוקים, שגיאות ותקלות. מובהר בזאת, כי בכל אחד מהמקרים המפורטים בסעיף זה לעיל, תהא החברה, לפי שיקול דעתה הבלעדי, רשאית לנקוט כל צעד על מנת למזער ו/או לנטרל את האמור, לרבות להפסיק ו/או לשנות את הפעילות, באופן מלא או חלקי, טרם תום תקופת הפעילות ו/או לבטל את הפעילות כליל ו/או לנקוט בכל צעד אחר, ולמשתתף/ת לא תעמוד כל טענה ו/או תביעה ו/או דרישה כלפי החברה ו/או מי מטעמה בקשר עם האמור. <br/><br/>
41. מבלי לגרוע מהאמור מובהר, כי עריכת הפעילות תלויה, בין היתר, במערכות ממוחשבות שונות, לרבות אתר הפעילות, שלחברה אין שליטה עליהן. החברה אינה מתחייבת כי הפעילות תתנהל ללא תקלה, שגיאה או טעות, ולמשתתף/ת לא תהיה כל טענה או תביעה כתוצאה או בקשר לכך. כמו כן, החברה תהיה רשאית, לפי שיקול דעתה, להפסיק את הפעילות, באופן מלא או חלקי, במהלך תקופת הפעילות, אם ייווצרו תנאים שישבשו ו/או ימנעו את המהלך התקין של הפעילות. <br/><br/>
42. מבלי לגרוע מכלליות האמור, החברה ו/או מי מטעמה לא יישאו בכל אחריות לכל תקלה טכנית, לגישה בלתי מורשית לנתונים, מחיקת נתונים או לתקלות אחרות, הקשורות באתר הפעילות, ברשת האינטרנט, בשרתים או בכל כלי או מערכות ממוחשבות אחרות, לרבות מחיקת נתונים, בין אם מדובר בתקלה כתוצאה מכוח עליון ובין אם מדובר בתקלה מערכתית באתר הפעילות, ברשת האינטרנט, בשרתים או בכל כלי או מערכת ממוחשבת אחרת. במקרה של תקלה כאמור, למשתתף/ת לא תהיה כל טענה ו/או תביעה ו/או דרישה כלפי החברה ו/או מי מטעמה. <br/><br/>
43. רישומי החברה ו/או מערכות המחשב של החברה ו/או מי מטעמה יהוו ראייה מכרעת לנכונותם ו/או בכל הקשור למסירת ו/או קליטת ו/או מדידת זמנים ו/או עיבוד נתונים ו/או פרטים ו/או לכל עניין אחר הקשור לביצוע הפעילות, תוצאותיה ולבחירת הזוכה, ולא תישמע כל טענה בדבר נכונות ו/או דיוק הנתונים ו/או כל טענה אחרת בקשר עם הפעילות.<br/><br/> 
44. מובהר כי אין להכניס באתר הפעילות שינויים, להעתיק, להפיץ, לשדר, להציג בפומבי, לבצע בפומבי, לשכפל, לשנות, לעבד, ליצור יצירות נגזרות, לפרסם, למכור, להשכיר או למסור לצד שלישי, בין אם באמצעות המשתתף ובין אם באמצעות צד שלישי, כל חלק מן אתר הפעילות בכל דרך או אמצעי בין אם אלקטרוניים, מכאניים, אופטיים, אמצעי צילום או הקלטה, או בכל אמצעי ודרך אחרת בלא קבלת הסכמתה של החברה או מי מטעמה מראש ובכתב. <br/><br/>
45. החברה תהיה רשאית למסור כל מידע אשר יידרש ממנה על פי חוק ו/או על פי הוראה של רשות מוסמכת. <br/><br/>
46. בכל מקרה בו משתתף/ת פעל/ה באופן בלתי ראוי לדעת החברה, לרבות אך לא רק, בדרך של פריצה לאתר הפעילות ו/או שינוי פרטי התקשרות ו/או במידה וישנו חשד ו/או חשש לאמיתות פרטי התקשרות, או כל פעולה בלתי ראויה של משתתף/ת, תהיה החברה רשאית, בהתאם לשיקול דעתה הבלעדי והמוחלט של החברה, לפסול משתתף/ת כאמור ו/או משתתפים/ות אחרים/ות אשר נתוניהם/ן שונו כמפורט לעיל, מלהשתתף בפעילות. <br/><br/>
47. כמו כן, יובהר, כי החברה ו/או מי מטעמה יהיו רשאים, לפי שיקול דעתה הבלעדי והמוחלט של החברה, לבצע, בכל שלב, בדיקות נוספות לגבי השתתפות של כל משתתף/ת, במידה ועלה חשש לאמיתות הנתונים ו/או חשש לפריצה לאתר הפעילות ו/או לפעולה באופן בלתי ראוי על ידי המשתתף/ת, וכן, לפסול את השתתפותו/ה. <br/><br/>
48. יתכן ותתרחשנה תקלות טכניות שימנעו, בין השאר, כניסה ו/או שימוש באתר הפעילות. יובהר כי רק משתתפים/ות שהניקוד שלהם/ן ופרטיהם נקלטו בפועל אצל החברה באופן מלא ומדויק יוכלו להשתתף בפעילות. שונות 
49. הוראות תקנון זה יחולו על הפעילות ויהוו את הבסיס המשפטי לכל דיון בין המשתתף/ת לבין החברה ו/או מי מטעמה. <br/><br/>
50. החברה תעמיד תרומה של חפיסות שוקולד מילקה לעמותה בישראל, שתיבחר לפי שיקול דעתה הבלעדי. סך התרומה הינו כמספר חפיסות המילקה בחדר הוירטואלי עם מספר החפיסות הגדול ביותר. <br/><br/>
51. כל אדם המעוניין להשתתף בפעילות מוותר כתנאי מוקדם לזכאותו להשתתף בפעילות על כל טענה בקשר עם אי קליטת פרטי ההתקשרות שלו או מחיקתם ממאגרי הנתונים של החברה. <br/><br/>
52. התקנון והנחיות הפעילות הרלוונטיות ממצים ומסדירים את היחסים בין המשתתף/ת לבין החברה, והשתתפות בפעילות מהווה ומבטאת הסכמה מלאה של המשתתף/ת לכל תנאי התקנון והנחיות הפעילות הרלוונטיות. <br/><br/>
53. החברה רשאית לשנות ו/או לעדכן ו/או להחליף את התקנון בכל עת, לפי שיקול דעתה הבלעדי והמוחלט, ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסום הקישור לתקנון באתר הפעילות.<br/><br/> 
54. החברה רשאית לשנות את הפרסים הניתנים במסגרת הפעילות בהתאם לשיקול דעתה הבלעדי והמוחלט, ובכלל זה לשנות את יעדי החופשה, ללא צורך במתן הודעה מוקדמת, ולא תהיה למשתתף/ת כל טענה או זכות כנגד שינוי זה. <br/><br/>
55. החברה רשאית לפרסם לפני תחילת תקופת הפעילות ובמהלכה, באתר הפעילות ובאמצעי מדיה אחרים, מידע אודות הפעילות, הסברים על הפעילות, ופרסומים אחרים בקשר עם הפעילות, באמצעות תמונות, סרטונים ופרסומים אחרים, במועדים ובאמצעים שונים, לפי שיקול דעתה הבלעדי של החברה. <br/><br/>
56. המשתתף/ת מאשר/ת כי בעצם השתתפותו/ה בפעילות, הוא/היא מסכים/ה לכך שכל הפרטים אשר נמסרו על ידו/ה במהלך הפעילות, לרבות כל הפרטים האישיים (להלן: "המידע") ישמשו את החברה ו/או מי מטעמה לצורך ניהול הפעילות, פרסום הפעילות והענקת הפרס, לרבות העברת הודעת זכיה באמצעות פרטי הקשר אשר מולאו במסגרת טופס ההשתתפות, וזאת מבלי ששימוש שכזה ייחשב כפגיעה בפרטיות. <br/><br/>
57. המשתתף/ת מאשר/ת ומסכים/ה לכך שמסירת המידע על ידו/ה נעשית על פי רצונו/ה החופשי ובידיעה שהמידע ישמש לצורך כאמור. <br/><br/>
58. מובהר בהקשרים אלה, כי (א) לא מוטלת על המשתתפים/ות כל חובה שבדין למסור או לחשוף את המידע (אך מובהר, כי מסירת/חשיפת פרטים כאמור מתחייבת לשם השתתפות בפעילות); וכן כי (ב) ידוע למשתתף/ת ומוסכם עליו/ה, כי בנוסף לשימוש שיעשה במידע, אגירתו והעברתו על פי תקנון זה לצרכי השתתפות בפעילות כאמור, החברה עשויה ותהא רשאית לעשות שימוש במידע כאמור, לאגור אותו במאגרי מידע (כהגדרת המונח בחוק הגנת הפרטיות, התשמ"א-1981) וכן להעבירו לתאגידים קשורים לה (לרבות חברות אם וחברות אחיות) ולשותפים עסקיים בכפוף לכך שההעברה כאמור היא לצורך פעילות זו, והכול בכפוף להוראות הדין, וכן כי (ג) עורכת המבצע תהא רשאית להעביר המידע אל מחוץ לגבולות המדינה, וזאת בהתאם לתקנות הגנת הפרטיות (העברת מידע מחוץ לגבולות המדינה), התשי"א-2001. <br/><br/>
59. מובהר כי בעוד החברה עושה כמיטב יכולתה על מנת להגן על שלמות המידע האישי המתקבל באתר ואיסוף המידע האישי ושמירתו נעשים בסביבה מאובטחת העושה שימוש תוך כדי שילוב של טכנולוגיות אבטחה, נוהלי אבטחה ואמצעים ארגוניים נוספים שנועדו כדי להגן על המידע האישי של המשתמש מפני גישה ומיועדים למזער את הסיכונים מפני גניבה, פגיעה, אובדן או גישה בלתי מורשית למידע, וכן תוך הקפדה על שמירת סודיות כנדרש, וכל זאת בהתאם לדרישות כל דין. אולם, אין וודאות שיישומים אלה מבטיחים באופן מוחלט שמידע אישי לא ייחשף או ייגנב ממאגרי המידע של החברה. אי לכך ובהתאם לזאת, החברה אינה מתחייבת, והמשתמש/ת אינו/ה יכול/ה באופן סביר לצפות שהשירותים ומערכות המידע המשמשות אותנו יהיו חסינים לחלוטין מפני גישה בלתי-מורשית ו/או פגיעה למידע האישי לגבי המשתתף/ת השמור על-ידי החברה. בעצם השימוש באתר ובשירותי החברה, המשתתף/ת מצהיר/ה כי הוא/היא מודעת ומסכים/ה למגבלות אלו. <br/><br/>
60. כמו כן המשתתף/ת מאשר/ת ומסכים/ה כי החברה תהיה רשאית לפרסם את המידע אודות המשתתף/ת, בכל צורה ובכל מדיה שהיא, בנוגע לפרסומים הנוגעים לזוכים/ות בפעילויות של החברה, וזאת לפי בחירת החברה ולפי שיקול דעתה הבלעדי. מובהר כי החברה תהא רשאית גם שלא לפרסם את המידע כלל. <br/><br/>
61. משתתף/ת אשר יזכה/תזכה באחד מהפרסים המפורטים בתקנון זה שהינו/ה בין הגילאים 16-18 יציג/תציג אישור אפוטרופוס לזכייה, אישור אפוטרופוס ליצירת קשר עמו/עמה וכן יעמוד/תעמוד בכל דרישות חוק האפוטרופוס הכללי, תשל"ח-1978. 
62. השתתפות בפעילות, מהווה הסכמה לקבלת דברי פרסומת (לרבות באמצעות דואר אלקטרוני, פקסימיליה, טלפון או דואר רגיל), כמו כן למשתתף/ת לא תהא כל תביעה ו/או דרישה ו/או טענה נגד החברה, ו/או מי מטעמה, בכל עניין ודבר הקשור, במישרין ו/או בעקיפין, במידע שהתקבל אצל החברה ו/או בשמירת המידע ו/או עשיית שימוש בו. <br/><br/>
63. החברה תהא רשאית, על פי שיקול דעתה הבלעדי, להודיע בכל עת על ביטול הפעילות ו/או הפסקתה. <br/><br/>
64. בכל מקרה של סתירה ו/או אי התאמה בין התקנון לבין כל פרסום אחר, בכתב או בעל-פה, מכל מין וסוג שהוא, תגברנה הוראות תקנון זה והן אשר תקבענה. <br/><br/>
65. החברה היא הפוסקת הסופית בכל עניין הנוגע לפעילות ובנוגע לכל שאלה שתתעורר במהלכה ו/או בקשר אליה ובנוגע לכל תקלה ו/או בעיה ו/או כל מצב בלתי צפוי שייווצר במהלכה, בין אם צוינו בתקנון ובין אם לאו ולמשתתפים/ות לא תהיה כל טענה בעניין. <br/><br/>
66. הצדדים מקנים בזאת סמכות שיפוט מקומית ייחודית ובלעדית בכל התדיינות בעניין הקשור בפעילות ו/או בהוראות אלה ויישומן אך ורק לבית המשפט המוסמך בעיר תל- אביב – יפו, לפי דיני מדינת ישראל.<br/><br/> 
67. ההשתתפות בפעילות אסורה על עובדי החברה, עובדי חברת דיפלומט מפיצים (1968) בע"מ, עובדי משרד הפרסום "בי אן ווי חברה לפרסום בע״מ" וכל מי מטעמם, לרבות בני משפחותיהם והם לא יהיו זכאים לפרס/ים גם במקרה שהשתתפו וזכו בניגוד לאמור לעיל. לעניין סעיף זה- "בן משפחה" – בן זוג, הורה, ילד, אח/ות. <br/><br/>
68. החברה לא תהא חייבת בתשלום מס כלשהו בגין חלוקת הפרסים. במידה שהפרסים, כולם או חלקם, יגררו תשלומי מיסים, יוטלו המיסים על הזוכים/ות בלבד. <br/><br/>
69. למען הסר ספק, הפעילות לפי תקנון זה אינה מהווה הגרלה כהגדרתה בסעיף 224 לחוק העונשין, התשל"ז- 1977, וזאת בשל העובדה כי הזכייה בו תלויה ביכולת ולא בגורל. 70. עותקים מן התקנון מצויים במשרדי החברה וקישור אליהם יופיע, במהלך תקופת הפעילות, באתר הפעילות. עותק מן התקנון ניתן יהא לקבל בשעות העבודה הרגילות במשרדי החברה. ט.ל.ח החברה מאחלת למשתתפים/ות בהצלחה!
               </span> </div>
                <img src={footer_img} className="ac-img-responsive ac-footer-img-milka" alt="milka footer" />
            </div>
        </div>
    );
}

export default TakanonPage;