import React from 'react';
import { Menu } from '../Components/Menu/Menu.js';
import { Link } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

// Import Css
import faqpage from '../assets/css/faqpage.css';

// Iport images
import  milka_logo_img  from '../assets/img/milka_logo.png';

export const FaqPage = () => { 
    return (
        <div className="ac-page ac-page-faq">
            <Menu />
            <div className="ac-container">
            <img src={milka_logo_img} className="ac-img-responsive ac-logo-img-milka" alt="milka logo" />
                <h1>שאלות ותשובות</h1>
                <div className="ac-accordion">
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            מהם תאריכי פעילות הפסטימילקה?    
                            <div className="ac-wrapper-dots">
                                <div className="ac-innerwrapper-dots">
                                    <div className="ac-dots"></div>
                                    <div className="ac-circle"></div>
                                </div>
                            </div>               
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            01.08.2023-14.08.2023
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            מאיזה גיל אפשר להשתתף בפסטימילקה?
                            <div className="ac-wrapper-dots">
                                <div className="ac-innerwrapper-dots">
                                    <div className="ac-dots"></div>
                                    <div className="ac-circle"></div>
                                </div>
                            </div>  
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            מגיל 18. בכפוף לאישור אפוטרופוס ניתן להשתתף מגיל 16.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            מה עושים אם אין לנו חשבונית כרגע?
                            <div className="ac-wrapper-dots">
                                <div className="ac-innerwrapper-dots">
                                    <div className="ac-dots"></div>
                                    <div className="ac-circle"></div>
                                </div>
                            </div>  
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            נוכל לשלוח לכם SMS עם קישור מיוחד, שמאפשר להתחבר אליו מתי שרוצים ולהוסיף באמצעותו חשבונית עד גמר הפעילות.           
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            מה הפרסים?
                            <div className="ac-wrapper-dots">
                                <div className="ac-innerwrapper-dots">
                                    <div className="ac-dots"></div>
                                    <div className="ac-circle"></div>
                                </div>
                            </div>  
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            המקום הראשון יזכה לשני כרטיסי טיסה לאיטליה וכניסה לפסטיבל השוקולד!
                            המקום השני פינוק זוגי הכולל שני לילות במלון יוקרה בישראל 
                            המקום השלישי יזכה בזוג כרטיסים לסרט VIP
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            כמה פעמים אפשר לשחק בפסטימילקה?
                            <div className="ac-wrapper-dots">
                                <div className="ac-innerwrapper-dots">
                                    <div className="ac-dots"></div>
                                    <div className="ac-circle"></div>
                                </div>
                            </div>  
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            אפשר לשחק ללא הגבלה בכפוף להצגת חשבונית בשווי 20 ₪ על מגוון מוצרי שוקולד ועוגיות מילקה, עבור כל טופס השתתפות.                    </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            איך אפשר ליצור איתכם קשר?    
                            <div className="ac-wrapper-dots">
                                <div className="ac-innerwrapper-dots">
                                    <div className="ac-dots"></div>
                                    <div className="ac-circle"></div>
                                </div>
                            </div>  
                        </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            אנו מזמינים אותך לשלוח לנו הודעה פרטית בעמוד <a href="https://www.instagram.com/milka__israel/?igshid=MzRlODBiNWFlZA==" rel="noopener" target="_blank">האינסטגרם של מילקה </a>       
                                    </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                </div>
       
            </div>
        </div>
    );
}

export default FaqPage;