import React, { useState, useEffect, createRef } from 'react';
import { Menu } from '../Components/Menu/Menu.js';


// Import Css
import trumapage from '../assets/css/trumapage.css';


// Iport images
import  footer_img  from '../assets/img/1.jpg';
import  milka_logo_img  from '../assets/img/milka_logo.png';
import  th_3  from '../assets/img/th_3.png';
import  mondelez  from '../assets/img/mondelez.png';
import  th_2  from '../assets/img/th_2.png';


export const TrumaPage = () => {
    return (
        <div className="ac-page ac-page-truma">
            <Menu />
            <div className="ac-container">
                <img src={milka_logo_img} className="ac-img-responsive ac-img-1a" alt="milka logo" />
                <span className="ac-trummmmm">
                    לעוד רגעים
                </span>
                <h1>שעושים טוב</h1>
            
                {/* <p>
                    מאחר ואנו במילקה מאמינים בעשיית <br/>
                    טוב לאנשים ולחברה, החלטנו לתרום את <br/>
                    כל החבילות לעמותת בית אלמוג שילוני, <br/>
                    המעניקה בית חם לאלפי חיילים בודדים. <br/>
                    מילקה -לרגעים שעושים טוב לכולם!
                </p> */}
                <p>
                {/* <strong class="ac-colorfull">לעוד רגעים שעושים טוב</strong><br/> */}
            אנחנו במילקה מאמינים בעשיית <br/>
            טוב אנשים ולחברה, לכן החלטנו לתרום <br/>
            כל החבילות לעמותת בית אלמוג שילוני, <br/>
            המעניקה בית חם לאלפי חיילים בודדים. <br/>
            מילקה -לרגעים שעושים טוב לכולם!
                </p>
                <div className="ac-box-shadow"></div>
                <img src={th_3} className="ac-img-responsive ac-img-1" alt="chocolad milka"/>
                <div className="ac-pos-text">
                    <span className="ac-add-ttl">הידעת?</span>
                <p><small>
                    שוקולד מילקה מכיל 100% <br/>
                    פולי קקאו, שמקורם <br/>
                    בתוכנית קיימות הקקאו <br/>
                    העולמית של   <img src={mondelez} className="ac-img-responsive ac-img-mondelez" alt="chocolad milka"/><br/>
                    המחויבת לפיתוח קהילות <br/>
                    מגדלי הקקאו וסביבתם.
                                </small></p>
                </div>
                
                
                
                <img src={th_2} className="ac-img-responsive ac-img-2" alt="chocolad milka"/>
                <p><small>
                מילקה משתמשת ברכיבים איכותיים אשר <br/>
                    נבחרו בקפידה, במטרה לייצר שוקולד איכותי <br/>
                    וקרמי, המכיל 100% חלב מהאלפים.
                    </small></p> 
                <img src={footer_img} className="ac-img-responsive ac-footer-img-milka" alt="milka footer" />
            </div>
        </div>
    );
}

export default TrumaPage;