import React, { useState, useEffect } from 'react';
import { Menu } from '../Components/Menu/Menu.js';
import { Countdown } from '../Components/Countdown/Countdown.js';
import { Link } from 'react-router-dom';
import TagManager from "react-gtm-module";
import { useNavigate } from 'react-router-dom';

// Import Css
import formpage from '../assets/css/formpage.css';

// Iport images
import  milkaLogoImg  from '../assets/img/milka_logo.png';
import  footerImg  from '../assets/img/1.jpg';
import  arrowImg  from '../assets/img/ctrl-left.svg';
import  cameraImg  from '../assets/img/camera.svg';
import  close  from '../assets/img/e-remove.svg';

export const FormPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [file, setFile] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedTakanon, setIsCheckedTakanon] = useState(false);
    const [openImagePopup, setOpenImagePopup] = useState(false);
    const [openSmsPopup, setOpenSmsPopup] = useState(false);
    const [isSmsSent, setIsSmsSent] = useState(false);
    const [openSecondSmsPopupGet, setOpenSecondSmsPopupGet] = useState(false);
    const [allowSecondSms, setAllowSecondSms] = useState(false);
    const [numImage, setNumImage] = useState('');
    const [userAnswer, setUserAnswer] = useState('');
    const [cont, setCont] = useState('');
    const [utmParams, setUtmParams] = useState({});
    const [showFormInvoice, setShowFormInvoice] = useState(false);
    
    const navigate = useNavigate();
  
    useEffect(() => { 
        const storedValue = localStorage.getItem('num_image');
        const storedValueUserAnswer = localStorage.getItem('userAnswer');
        let storedValueCont = localStorage.getItem('user_cont');
        storedValueCont = JSON.parse(storedValueCont);

        setTimeout(() => {
            setNumImage(storedValue);
            setUserAnswer(storedValueUserAnswer);
            setCont(storedValueCont);
        }, "5000");


         // Function to extract UTM parameters from the URL
        const getUtmParamsFromURL = () => {
            const searchParams = new URLSearchParams(window.location.search);
            const utmParams = {
            utm_source: searchParams.get('utm_source') || '',
            utm_medium: searchParams.get('utm_medium') || '',
            utm_campaign: searchParams.get('utm_campaign') || '',
            utm_term: searchParams.get('utm_term') || '',
            utm_content: searchParams.get('utm_content') || '',
            };
            setUtmParams(utmParams);
        };
  
        getUtmParamsFromURL();
    }, []);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const handleCheckboxChangeTakanon = (event) => {
        setIsCheckedTakanon(event.target.checked);
    };

   

    const handleIsImagePopup = () =>{

        if(file !== ''){
            return false;
        } else{
            if(openImagePopup){
                setOpenImagePopup(false);
            } else{
                setOpenImagePopup(true);
            }
        }
       
        
    }

    const handleSmsPopup = () =>{

        // if(tel == '') {
        //     alert('אנא הקלד את המספר טלפון ')
        // } else {
        //     if(openSmsPopup){
        //         setOpenSmsPopup(false)
        //     } else{
        //         setOpenSmsPopup(true)
        //     }
        // }
        if(openSmsPopup){
                    setOpenSmsPopup(false)
            } else{
                    setOpenSmsPopup(true)
        }
        
        
    }

    // Function to update the numberChoosenImage
    const updateUpdateCountDown = (newValue) => {

        if(newValue == 'ac-available') {
            console.log(newValue);
            setAllowSecondSms(true);
        } 
      
       
    };
    const handleSmsPopupGet = () =>{
       setOpenSecondSmsPopupGet(true)
    }

    const handleSubmitSMS = async (e) => {
        TagManager.dataLayer({ dataLayer: { event: "send_sms" } })
        // console.log("DATA:", data);
        e.preventDefault();
        const dataSend = {
          phone: tel,
        };
    
        try {
          let res = await fetch("https://dev.activated.co.il/sendSMS2.php", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(dataSend),
          });

          let response = res;
           console.log('res: ', res);
           console.log('res: ', typeof res);

           // Check if 'status: 200' exists in the object
        if (response.status === 200) {
            console.log('Status: 200 exists in the object.');
            setIsSmsSent(true);
        } else {
            console.log('Status: 200 does not exist in the object.');
            setIsSmsSent(false);
        }
       
        //   let resJson = await res.json();
        //   if (res.status === 200) {
        //     console.log("Sent");
        //     setIsSmsSent(true);
        //   } else {
        //     console.log("Not Sent");
        //     setIsSmsSent(false);
        //   }
        } catch (err) {
          console.log(err);
        }
    };

    const handleSubmitInvoice = (e) => {
        e.preventDefault();
        const form = document.getElementById('form-app');

        // console.log('file: ', file);
      
        if(file == '') {
            alert('אנא צרף חשבונית');
        } else {
            form.submit();
        }
    }

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        TagManager.dataLayer({ dataLayer: { event: "form_submit" } })

        const dataSend = {
            phone: tel,
            fullname:name,
            email:email,
            userAnswer: userAnswer,
            numImage: numImage,
            checked: isChecked,
            checkedTakanon: isCheckedTakanon,
            utm_campaign: utmParams.utm_campaign,
            utm_medium: utmParams.utm_medium,
            utm_source:utmParams.utm_source,
            utm_term: utmParams.utm_term,
            cont: cont,
        };
        
          
            // alert('no file');
       
            // alert('Did not attached the file');
            // console.log('Did not attached the file');
           
            try {
                // let res = await fetch("https://festimilka.co.il/api.php", {
                let res = await fetch("https://dev.activated.co.il/api.php", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify(dataSend),
                });
      
                let response = res;
                 console.log('res: ', res);
                 console.log('res: ', typeof res);
      
                 // Check if 'status: 200' exists in the object
              if (response.status === 200) {
                  console.log('Status: 200 exists in the object.');
                  setShowFormInvoice(true);
              } else {
                  console.log('Status: 200 does not exist in the object.');
                 
              }
             
              } catch (err) {
                console.log(err);
              }
       
        
    }

    return (
        <div className="ac-page ac-page-form">
            <Menu />
            <div className="ac-container">
                <img src={milkaLogoImg} className="ac-img-responsive ac-milka-logo-img" alt="logo milka" />
                {/* <span className="ac-title-form">מחכים לקופי חדש <br/>לשורה הזאת</span> */}

                {
                    !showFormInvoice ? (
                        <div>
                            <span className="ac-title-form">שיחקת אותה!</span>
                            <span className="ac-text-form">כדי שנוכל ליצור קשר במקרה של זכייה, <br/>
                            נצטרך מעט פרטים וצילום חשבונית:
                            </span>
                        </div>
                       
                    ) : (
                        <div>
                            <span className="ac-title-form">צירוף חשבונית</span>
                            <span className="ac-text-form">
                                 ניתן להעלות חשבונית על רכישת מוצרי <br/>
                                מילקה/עוגיות בסך 20 ₪ ומעלה <br/>
                                עד גמר הפעילות ב-14.08.23
                            </span>
                        </div>
                    )
                }


                {
                        openSmsPopup && !isSmsSent ? (
                            <div className="ac-sms-popup">
                            <div className="ac-overlay"></div>
                                <div className="ac-innerimage-popup">
                                    <img src={close} className="ac-img-responsive ac-remove-popup" alt="לסגור את הפופ אפ" onClick={handleSmsPopup}/>
                                    <div className="ac-content-wrapper">
                                    <span className="ac-popup-title">חסרה לך חשבונית</span><br/>
                                        <span className="ac-popup-text">
                                        <strong>
                                        שמנו לב שחסרה לך חשבונית <br/>
                                            ניתן להעלות חשבונית על רכישת <br/>
                                            מוצרי מילקה/עוגיות בסך 20 ₪ ומעלה <br/>
                                            עד גמר הפעילות ב-14.08.23
                                                <br/> <br/>
                                          איך?</strong> <br/>
                                                קליק למטה על הכפתור ו-<span className="ac-en-light">SMS</span> <br/>
                                                יישלח אליך עם לינק <br/>
                                                כפתור: שלחו לי <span className="ac-en-light">SMS</span>
                                        </span>
                                        <form onSubmit={handleSubmitSMS}>
                                            <button type="submit" className="ac-btn">
                                                            <span>שלחו לי <span className="ac-en-font-bold">SMS</span></span>
                                                            <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="לשליחת SMS יש ללחוץ כאן" />
                                            </button>
                                        </form>
                                        <span className="ac-popup-text">
                                        חשוב לזכור, בלי חשבונית <br/>
                                                לא ניתן יהיה לזכות... וחבל
                                        </span>
                                    </div>
                                </div>
                        </div>
                        ) : (
                            ''
                        )
                }  

                {
                     isSmsSent && !openSecondSmsPopupGet ? (
                   
                        <div className="ac-sms-popup ac-sms-popup-get">
                        <div className="ac-overlay"></div>
                            <div className="ac-innerimage-popup">
                                <img src={close} className="ac-img-responsive ac-remove-popup" alt="לסגור את הפופ אפ" onClick={handleSmsPopupGet}/>
                                <div className="ac-content-wrapper">
                                    <span className="ac-popup-title">יש לך את זה?</span><br/>
                                    <span className="ac-popup-text">
                                             שלחנו לך <span className="ac-en-font-bold">SMS</span> <br/>
                                            עכשיו תורך לקנות שני מוצרי מילקה <br/>
                                            בשווי 20₪ (שוקולד ו/או עוגיות) <br/>
                                            ולהעלות חשבונית.
                                       
                                      
                                    </span><br/>
                                    <span className="ac-popup-text"><strong>בהצלחה!</strong></span><br/>
                                    <button type="button" id="ac-thankyou-sms" className="ac-thankyou-sms" onClick={()=>{
                                        handleSmsPopupGet();
                                        var $url_th = 'https://festimilka.co.il/thankyou/';
                                        window.location.replace($url_th);
                                        }}>קיבלתי. תודה</button>
                                    <form onSubmit={handleSubmitSMS}>
                                        <button type="submit" className={allowSecondSms ? 'ac-btn ac-btn-second-sms' : 'ac-btn ac-btn-second-sms ac-not-allowed'}>
                                            <span>לא קיבלתי, שלחו שוב (<Countdown updateCountDown={updateUpdateCountDown}/>)</span>
                                        </button>
                                    </form>
                                    
                                </div>
                            </div>
                    </div>
                    ) : (
                        ''
                    )
                }

                {
                    !showFormInvoice ? (
                        <div className="ac-form-wrapper ac-form-wrapper-details">
                            <form onSubmit={handleSubmit}>
                                    <fieldset className="row">
                                        <div className="form-group required-control">
                                            <label  className="control-label" for="text_1">שם</label>
                                            <input type="text" id="text_1" name="text_1" value={name}  onChange={(e)=>setName(e.target.value)} data-alias="" maxlength="25" placeholder="שם" className="form-control" required/>
                                        </div>
        
                                        <div className="form-group required-control">
                                            <label  className="control-label" for="email_1">דוא"ל</label>
                                            <input type="email" id="email_1" name="email_1" value={email}  onChange={(e)=>setEmail(e.target.value)} data-alias="" placeholder='דוא"ל' className="form-control" required/>
                                        </div>
        
                                        <div className="form-group required-control">
                                            <label  className="control-label" for="text_2">טלפון</label>
                                            <input type="tel" id="text_2" name="text_2" value={tel}  onChange={(e)=>setTel(e.target.value)} data-alias="" minlength="10" maxlength="10" placeholder="טלפון" className="form-control" required/>
                                        </div>
                                        <div className="form-action">
                                            <button type="submit" id="button_1" name="button_1" className="btn ac-btn btn-primary">
                                                <span>שליחה</span>
                                                <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="כנסו לחדר" />
                                            </button>
                                        </div> 
        
                                        <div className="form-group required-control"> 
                                            <label  className="control-label" for="checkbox_2"> הסכמה לצירוף חשבונית </label>
                                            <div className="checkbox ">
                                                <input type="checkbox" name="checkbox_2[]" id="checkbox_2_0" value="כן" data-alias="" data-required="true" checked={isChecked} onChange={handleCheckboxChange} required/>
                                                <label for="checkbox_2_0" className="checkbox-inline">ידוע לי שכדי לזכות, צריך לצלם חשבונית <br/>
                                    על שני מוצרי מילקה בסך ₪20 (שוקולד ו/או עוגיות)</label>
                                            </div> 
                                            <span id="checkbox_2"></span>
                                            
                                        </div>
        
                                        <div className="form-group required-control"> 
                                            <label  className="control-label" for="checkbox_1">קראתי את התקנון</label>
                                            <div className="checkbox ">
                                                <input type="checkbox" name="checkbox_1[]" id="checkbox_1_0" value="כן" data-alias="" data-required="true" checked={isCheckedTakanon} onChange={handleCheckboxChangeTakanon} required/>
                                                <label for="checkbox_1_0" className="checkbox-inline">קראתי את&nbsp;<Link to="/takanon" className="ac-link-takanon"> תקנון </Link> </label> 
                                            </div> 
                                            <span id="checkbox_1"></span>
                                            
                                        </div>
                                        <input type="hidden" id="hidden_1" name="hidden_1" value={numImage} data-alias="" data-label="מספר תמונה במשחק"/>
                                        <input type="hidden" id="hidden_2" name="hidden_2" value="" data-alias="" data-label="האם יוזר מנצח?"/>
                                        <input type="hidden" id="hidden_3" name="hidden_3" value={userAnswer} data-alias="" data-label="תשובה של יוזר"/>
                                        <input type="hidden" id="hidden_4" name="hidden_4" value="" data-alias="" data-label="utm_term"/>
                                        <input type="hidden" id="hidden_5" name="hidden_5" value="" data-alias="" data-label="utm_source"/>
                                        <input type="hidden" id="hidden_6" name="hidden_6" value="" data-alias="" data-label="utm_medium"/>
                                        <input type="hidden" id="hidden_7" name="hidden_7" value="" data-alias="" data-label="utm_campaign"/>
                                    </fieldset>
                                    <div className="" style={{'display':'none'}}><label className="control-label" for="_email">Excuse me, but leave this field in blank</label>
                                    <input type="text" id="_email" className="form-control" name="_email"/></div> 
                            </form>
                        </div>
                    ) : (
                        <div className="ac-form-wrapper ac-form-wrapper-invoice">
                            <form action="https://crud.activated.digital/app/app/f?id=40" onSubmit={handleSubmitInvoice} method="post" enctype="multipart/form-data" accept-charset="UTF-8" id="form-app"  encType="multipart/form-data">
                                <fieldset className="row">


                                        <div className="form-group required-control ac-wrpr-hide">
                                            <label  className="control-label" for="text_1">שם</label>
                                            <input type="text" id="text_1" name="text_1" value={name}  onChange={(e)=>setName(e.target.value)} data-alias="" maxlength="25" placeholder="שם" className="form-control" required/>
                                        </div>

                                        <div className="form-group required-control ac-wrpr-hide">
                                            <label  className="control-label" for="email_1">דוא"ל</label>
                                            <input type="email" id="email_1" name="email_1" value={email}  onChange={(e)=>setEmail(e.target.value)} data-alias="" placeholder='דוא"ל' className="form-control" required/>
                                        </div>

                                        <div className="form-group required-control ac-wrpr-hide">
                                            <label  className="control-label" for="text_2">טלפון</label>
                                            <input type="tel" id="text_2" name="text_2" value={tel}  onChange={(e)=>setTel(e.target.value)} data-alias="" minlength="10" maxlength="10" placeholder="טלפון" className="form-control" required/>
                                        </div>

                                        <div className="ac-click-for-image file-custom" onClick={()=>{
                                            handleIsImagePopup();
                                            TagManager.dataLayer({ dataLayer: { event: "reciept_open" } })
                                            }}>
                                            <span>צילום חשבונית</span>
                                            <img src={cameraImg} className="ac-img-responsive ac-camera-img" alt="צילום חשבונית" />
                                        </div>
                                        {
                                            openImagePopup ? (
                                                <div className={file !=='' ? 'ac-form-popup ac-image-popup ac-hide' : 'ac-form-popup ac-image-popup' }>
                                                    <div className="ac-overlay"></div>
                                                    <div className="ac-innerimage-popup">
                                                        <img src={close} className="ac-img-responsive ac-remove-popup" alt="לסגור את הפופ אפ" onClick={handleIsImagePopup}/>
                                                        <div className="ac-btns-wrapper">
                                                            <div className="form-group form-group-image">
                                                                <label  className="control-label" for="file_1">
                                                                    <span class="ac-get-image">
                                                                        <span>העלאה / צילום</span>
                                                                    </span>
                                                                </label>
                                                                <input type="file" id="file_1" name="file_1[]" data-alias="" accept=""  onChange={(e)=>{
                                                                    setFile(e.target.files[0]);

                                                                    TagManager.dataLayer({ dataLayer: { event: "reciept_upload" } })
                                                                    }
                                                                    } />
                                                            </div>
                                                            <div className="ac-send-image-later" onClick={()=>{
                                                                handleIsImagePopup();
                                                                TagManager.dataLayer({ dataLayer: { event: "reciept_later" } })
                                                                var $url_th = 'https://festimilka.co.il/thankyou/';
                                                                window.location.replace($url_th);
                                                                }}>
                                                                <span>אעלה מאוחר יותר</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        }
                                        
                                        

                                        <div className="form-action">
                                            <button type="submit" id="button_1" name="button_1" className="btn ac-btn btn-primary">
                                                <span>שליחה</span>
                                                <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="כנסו לחדר" />
                                            </button>
                                        </div> 

                                        <button type="button" id="ac-btn-popup-open" className="ac-btn-popup-open" onClick={handleSmsPopup}>ואם אין לי חשבונית כרגע?</button>


                                        <div className="form-group required-control"> 
                                            <label  className="control-label" for="checkbox_2"> הסכמה לצירוף חשבונית </label>
                                            <div className="checkbox ">
                                                <input type="checkbox" name="checkbox_2[]" id="checkbox_2_0" value="כן" data-alias="" data-required="true" checked={isChecked} onChange={handleCheckboxChange} required/>
                                                <label for="checkbox_2_0" className="checkbox-inline">ידוע לי שכדי לזכות, צריך לצלם חשבונית <br/>
                                    על שני מוצרי מילקה בסך           ₪20 (שוקולד ו/או עוגיות)</label>
                                            </div> 
                                            <span id="checkbox_2"></span>
                                            
                                        </div>

                                        <div className="form-group required-control"> 
                                            <label  className="control-label" for="checkbox_1">קראתי את התקנון</label>
                                            <div className="checkbox ">
                                                <input type="checkbox" name="checkbox_1[]" id="checkbox_1_0" value="כן" data-alias="" data-required="true" checked={isCheckedTakanon} onChange={handleCheckboxChangeTakanon} required/>
                                                <label for="checkbox_1_0" className="checkbox-inline">קראתי את&nbsp;<Link to="/takanon" className="ac-link-takanon"> תקנון </Link> </label> 
                                            </div> 
                                            <span id="checkbox_1"></span>
                                            
                                        </div>

                                        <input type="hidden" id="hidden_1" name="hidden_1" value={numImage} data-alias="" data-label="מספר תמונה במשחק"/>
                                        <input type="hidden" id="hidden_2" name="hidden_2" value="" data-alias="" data-label="האם יוזר מנצח?"/>
                                        <input type="hidden" id="hidden_3" name="hidden_3" value={userAnswer} data-alias="" data-label="תשובה של יוזר"/>
                                        <input type="hidden" id="hidden_4" name="hidden_4" value="" data-alias="" data-label="utm_term"/>
                                        <input type="hidden" id="hidden_5" name="hidden_5" value="" data-alias="" data-label="utm_source"/>
                                        <input type="hidden" id="hidden_6" name="hidden_6" value="" data-alias="" data-label="utm_medium"/>
                                        <input type="hidden" id="hidden_7" name="hidden_7" value="" data-alias="" data-label="utm_campaign"/>


                                </fieldset>
                                <div className="" style={{'display':'none'}}><label className="control-label" for="_email">Excuse me, but leave this field in blank</label>
                                <input type="text" id="_email" className="form-control" name="_email"/></div>
                            </form>
                        </div>
                    )
                }
               
                {/* <img src={footerImg} className="ac-img-responsive ac-milka-footer-img" alt="footer milka" /> */}
            </div>
        </div>
    );
}

export default FormPage;